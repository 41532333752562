import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import './LoginForm.css';

const AuthLogin = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  return (
    !isAuthenticated && (
    <>
        <div>
          <img className="pandaImage" src="panda_circle.png" alt="panda Image" /> 
        </div>
        
        <div className='container'>
             <form className="login" action="">
                <h2>Welcome to the Protenus Hub</h2>
                <h3>Sign in once to get access to all</h3>
                <h3>Protenus has to offer</h3>
                 <button className='button' onClick={() => loginWithRedirect()}>Sign In</button>
             </form>
         </div>
         
    </>
    )

  )
};

export default AuthLogin;
