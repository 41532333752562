import './App.css';
import LoginButton from './components/LoginButton';
// import LogoutButton from './components/LogoutButton';
import Profile from './components/Profile';
import Navbar from './components/Navbar';
import { useAuth0 } from "@auth0/auth0-react";


function App() {
  const { isLoading, error } = useAuth0();

  return (
    <div className="App">
      {error && <p>Authentication Error</p>}
   
      {!error && !isLoading && (
        <>
         <Navbar />
         <LoginButton />
         <Profile />
         </>
      )}
    </div>

  )
}

export default App;